<template>
  <div class="b2c-page-bg">  
    <div class="b2c-o-h">
      <validation-observer ref="infoValid">
        <div class="fs-sp">
          <div class="w-100 b2c-bg">
            <div class="w-100 b2c-bg-top">
              <div class="b2c-top-left"></div>
              <div class="b2c-top-center">Thông tin sản phẩm</div>
              <div class="b2c-top-right"></div>
            </div>
            <div class="b2c-text-solo pl-2 pr-2">
              Số thuê bao iTel:<br>
              <span class="ml-1 badge text-primary text_lg bg-white b2c-badge" style="font-size:40px">{{f087(info.Phone)}}</span>
            </div>
          </div>
          <div class="w-100 b2c-float-bg">
          </div>
          
          <div class="w-100 pb-2 pr-2 pl-2 pt-0">
            <b-card body-class="p-1" class="b2c-package-bg-selected">
              <b-card-text>
                <div class="d-flex border-bottom pb-1"> 
                  <div class="b2c-package-title-selected">{{info.Pack}}</div>
                  <div class="b2c-package-subtitle-selected">{{info.Price|number}}đ<span style="font-size:16px;padding-bottom:3px">/ tháng</span></div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="w-100 b2c-package-brief-selected" style="padding:6px !important;line-height:1.5;margin-bottom:10px">
                      <p>Gói cước: {{info.Pack}}</p>
                      <p>loại số: {{info.group_name}}</p>
                      <p>Thời gian cam kết: {{info.ThoiGianCamKet}}</p>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
        <div class="fs-pc">
          <div class="b2c-pc-pack-content">
            <div class="row">
              <div class="col-12 b2c-pc-payment">
                <h2 class="text-center h1 mt-3">Thông tin sản phẩm</h2>
                <div class="package mt-1">
                  <div class="b2c-text-solo pt-2 pl-2 pr-2">
                    Số thuê bao iTel:<br>
                    <span class="ml-1 badge text-primary text_lg bg-white b2c-badge" style="font-size:40px">{{f087(info.Phone)}}</span>
                  </div>
                  <div class="w-100 pb-2 pr-2 pl-2 pt-2">
                    <b-card body-class="p-1" class="b2c-package-bg-selected p-1">
                      <b-card-text>
                        <div class="d-flex border-bottom pb-1">
                          <div class="b2c-package-title-selected">{{info.Pack}}</div>
                          <div class="b2c-package-subtitle-selected">{{info.Price|number}}đ<span style="font-size:16px">/ tháng</span></div>
                        </div>
                        <div class="row">
                          <div class="col-12 mb-2">
                            <div class="w-100 b2c-package-brief-selected" style="padding:6px !important;line-height:1.5;margin-bottom:10px">
                              <p>Gói cước: {{info.Pack}}</p>
                              <p>loại số: {{info.group_name}}</p>
                              <p>Thời gian cam kết: {{info.ThoiGianCamKet}}</p>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required,min,email } from '@validations'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from 'v-select2-component';
import { BCardHeader, BCard,VBTooltip, BRow, BCol,BButton, BFormInput, BPagination, BCardText,BFormGroup,BFormCheckboxGroup, BFormCheckbox, BFormRadio, BFormRadioGroup,BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { WebCam } from "vue-web-cam";
import Vue from 'vue'
import vSelect from 'vue-select'
import StarRating from 'vue-star-rating'
import Fuse from 'fuse.js'
import { $ctx } from '@themeConfig'

Vue.component('v-select', vSelect)

extend('msisdn', value => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== '8487' && first3 !== '087') {
    return false;
  }
  else if (first4 === '8487' && value.length !== 11
    || first3 === '087' && value.length !== 10) {
    return false;
  }
  return true;
});

extend('num', value => {
  return !/\D/.test(value);
});
extend('space', value => {
  return value.indexOf(" ")>0;
});
extend('min0', value => {
  return value > 0;
});

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {BCardHeader,
    Select2,BCard, BRow, BCol,BFormInput,BFormGroup,
    ValidationProvider, ValidationObserver,BFormRadio, BFormRadioGroup,BFormCheckboxGroup,
    BInputGroup, BInputGroupAppend,BButton,FeatherIcon,BFormCheckbox,BCardText,BPagination,
    'vue-web-cam': WebCam,VBTooltip,StarRating, Fuse
  },
  data() {
    return {
      info:{}
    }
  },
  watch: {
    $route(to, from) {
      this.loadItems()
    },
    
  },
  created() {
    this.loadItems();
  },
  
  methods: {
    f087(value){
      if (value){
        return value.substr(0,4)+" "+value.substr(4)
      }else{
        return value
      }
    },
    
    loadItems(){
      this.loading(true);
      this.isLoading=true;
      useJwt.get('product/'+this.$route.params.msisdn).then(response =>{
        this.info = response.data.result;
        console.log(this.info)
        this.loading(false);
        this.isLoading=false;
      })
    },
    
    Img(pic){
      return images[pic];
    },
    
  }
}
</script>
<style lang="scss">
.check-box-policy{
  display: flex;
  align-items: center;
  .custom-control-label{
    padding-left: 15px !important;
  }
}
</style>
<style>
.table-step{
  width: 100%;
}
.table-step td{
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}
.b2c-bg-top{
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
}
.b2c-top-left{
  height: 44px;
  padding:8px 14px;
  width: 20%;
}
.b2c-top-right{
  width: 20%;
}
.b2c-top-right1::before{
  width: 20%;
}
.b2c-top-center{
  width: 80%;
  height: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 7px;
}
.b2c-steps{
  width: 100%;
  height: 42px;
  background: #D71A1F;
}

.b2c-text-solo{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
  text-align: center;
}
.b2c-bg{
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-number-087{
  width: 25%;
  text-align: right;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}
.b2c-number-msisdn{
  width: 50%;
  padding: 0px 10px;
}
.b2c-number-input::placeholder{
  color: rgba(0,0,0,0.3);
  font-size: 16px;
  letter-spacing: 0px;
  padding-left: 0px;
}
.b2c-number-input{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ED1F24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius:8px;
	border-radius:8px;
  letter-spacing: 3px;
  padding-left: 10px;
}
.b2c-number-btn{
  width: 25%;
}
.b2c-number-btn button{
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius:8px;
	border-radius:8px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==') no-repeat center;
}
.b2c-number--container{
  padding: 0 25px;
  height: 36px;
  background: #D71A1F;
  width: 100%;
}
.b2c-number-except{
  margin: 0 25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-except-e{
  margin: 0 25px;
  height: auto;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-type{
  margin-left:25px;
  margin-right:25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-price{
  margin: 0 25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 30px;
}
.b2c-number-except-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}
.b2c-number-except-buttons{
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}
.b2c-number-except-btn{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K') no-repeat;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin:8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}
.b2c-number-except-btn-checked{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==') no-repeat;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin:8px 8px 8px 0px;
  color: #ED1F24;
  width: 29px;
  height: 29px;
}
.b2c-number-except-colapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}
.b2c-number-except-nocolapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}
.b2c-number-type-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}
.b2c-number-type-select option{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.b2c-number-type-select{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}
.b2c-search-button{
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #FFFFFF;
  -webkit-border-radius:26px;
	border-radius:26px;
  margin: 25px;
}
.b2c-search-button-red{
  color: #FFFFFF !important;
  background: #ED1F24 !important;
}
.b2c-pagination-title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}
.b2c-pagination-title-sort{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius:6px;
	border-radius:6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;

}
.b2c-page-bg{
  background: rgba(242, 242, 242, 1);
}
.b2c-badge{
  font-size: 14px;
}
.b2c-buy-btn{
  border: 2px solid #ED1F24;
  -webkit-border-radius:26px;
	border-radius:26px;
  font-size: 16px;
  color: #ED1F24;
  height: 34px;
  width: 96px;
}
.b2c-page-msisdn{
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}
.b2c-camket-text{
  font-size:14px;
  margin-top: 12px;
}
.b2c-price-text{
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color:#424242;
}
.b2c-price-text b{
  color:#ED1F24;
}
.b2c-package-bg-selected{
  background: #f9f9f9;
}
.b2c-package-title-selected{
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ED1F24;
}
.b2c-package-subtitle-selected{
  font-family: 'Roboto';
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width:50%;
  color: #424242;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;
}
.b2c-package-subtitle-selected p {
  padding-bottom: 2px;
}
.b2c-package-brief-selected{
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}.b2c-package-brief-selected li{
  margin-top:10px;
}
.b2c-package-brief-selected li::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
  padding-right: 10px;
  vertical-align: sub;
}
.b2c-package-brief-selected .pack-txt{
  text-align: left;
}
.b2c-package-title{
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ED1F24;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}
.b2c-package-subtitle{
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}
.b2c-package-brief{
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}.b2c-package-brief li{
  margin-top:10px;
}
.b2c-package-brief li::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
  padding-right: 10px;
}
.b2c-package-brief .pack-txt,.b2c-package-brief-selected .pack-txt{
  text-align: left;
}
.b2c-package-brief-selected .pack-txt,.b2c-package-brief .pack-txt{
  margin-bottom: 10px;
}
.b2c-float-bg{
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}
.b2c-cart-price{
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius:10px;
  right: 20px;
  background: #18D3C9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}
.b2c-cart-price span{
  font-size: 24px;
}
.b2c-cart-add-more{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.b2c-cart-info-title{
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding:  10px 24px;
  border-bottom: 1px solid #e0e0e0;
}
.b2c-agree-text button{
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}
.b2c-store-colapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}
.b2c-store-nocolapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}
.b2c-bg-selected{
  background: #17E0D5;
}
.b2c-bg-selected .b2c-page-msisdn,.b2c-bg-selected .b2c-price-text,.b2c-bg-selected .b2c-camket-text,.b2c-bg-selected .b2c-price-text b{
  color: #fff;
}
.b2c-text-solo h1{
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}
.b2c-text-solo p{
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}
.b2c-search-button-red:disabled{
  background: #999999 !important;
}
.b2c-cart-bg-del{
  border-radius: 26.8px;
  -webkit-border-radius:26.8px;
  border: 1.5px solid #ED1F24;
  padding: 6px 16px;
}
.b2c-price-texts{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.b2c-rate-text{
  font-size: 20px;
  line-height: 1.4;
}
.vue-star-rating{
  display: inline-block !important;
}
.b2c-ghtk-fee{
  font-size: 14px;
  text-decoration-line: line-through !important;
}
.b2c-o-h{
  max-width: 100%;
  overflow-x: hidden;
}
.text1{
  font-size: 12px;
  line-height: 1.5;
}
.b2c-page-bg .custom-checkbox.custom-control{
  padding-left: 2.2rem;
}
.b2c-page-bg .custom-control-label{
  line-height: 1.2;
}
.b2c-pc-pack-content{
  min-height: 550px;
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
}
.b2c-pc-pack-content .package{
  width: 558px;
  height: 304px;
  background: #ED1F24;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 160px;
  position: relative;
}
.b2c-pc-pack-content .package h4{
  font-size: 16px;
  text-align: center;
  padding-top:30px;
  color:#fff;
}
.b2c-pc-pack-content .package h2{
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 36px;
  text-align: center;
  color:#fff;
}
.b2c-pc-pack-content .package .subpackage{
  width: 369px;
  height: auto;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 94.5px;
  margin-top:20px;
}
.b2c-pc-pack-content .package .subpackage .sub-header{
  border-bottom: 1px solid #0000001A;
  display: flex;
  height: 64px;
}
.b2c-pc-pack-content .package .subpackage h2{
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #ED1F24;
  font-style: italic;
  width: 50%;
  text-align: left;
  padding: 14px 0px 0px 28px;
}
.b2c-pc-pack-content .package .subpackage h4{
  width: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #424242;
  padding-top: 10px;
  padding-right: 30px;
  text-align: right;
}
.b2c-pc-pack-content .package .subpackage h4>span{
  font-weight: 400;
  font-size: 14px;
}
.b2c-pc-pack-content .package .subpackage .pack-txt{
  padding: 6px 15px 0px 15px;
  max-width:100%;
}
.b2c-pc-agree,.b2c-pc-pack-content .pc-step2-button{
  max-width: 558px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
}
.b2c-pc-pack-content .package .b2c-cart-bg{
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}
.b2c-pc-pack-content .b2c-cart-add-more{
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}
.b2c-pc-pack-content .b2c-pc-payment .package{
  height: 200px;
}
.b2c-pc-pack-content .b2c-pc-info{
  margin-top: 50px;
}
.b2c-pc-pack-content .b2c-pc-info .card-header{
  background: #F8F8F8;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.b2c-pc-pack-content .package .pack-txt{
  padding: 6px 15px 0px 15px;
  max-width:100%;
}
.b2c-pc-pack-content .b2c-package-bg-selected{
  max-width: 457px;
  margin-left: auto;
  margin-right: auto;
}
</style>
